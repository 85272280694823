.backgroundMobileDiv {
  background-image: url('../../assets/About/backgroundAboutMobileSection.png');
  /* height: 50vh !important; */
}

.platformH3 {
  font-size: 1.875rem !important;
  color: #273C97;
  font-weight: 700;
}

.comptative {
  background-image: url('../../assets/About/comptative.png');
  height: 70vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  /* justify-content: center !important; */
}

html:lang(ar) .comptative {
  background-image: url("../../assets/About/CompatitveAr.png");
}

html:lang(ar) .comptative>div {
  direction: rtl;
}

.textinsidecomptative {
  transform: translate(50px);
  font-size: 45px;
  font-weight: 700;
  margin-left: 50px !important;
}

.textinsidecomptativep {
  transform: translate(50px);
  width: 50%;
  font-size: 18px;
  font-weight: 700;
  margin-left: 50px !important;
}

@media only screen and (max-width: 48rem) {
  .comptative {
    background-color: #273C97 !important;
    background-image: none !important;
    height: 70vh !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }

  .platformH3 {
    font-size: .8rem !important;
    text-align: center;
  }

  .textinsidecomptativep {
    transform: none !important;
    /* width: 50%; */
    font-size: 15px;
    font-weight: 100;
    text-align: center;
    margin: auto;
  }

  .textinsidecomptative {
    transform: none !important;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }
}