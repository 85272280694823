.title {
    margin: 20px 0px;
    text-align: center;
}


#partnersContainer {
    min-height: 63vh;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem 12rem;
    padding-inline: 0.65rem;
    margin: 15px 5px;
}

.partnerImg {
    display: inline;
    width: 200px;
    object-fit: scale-down;
    aspect-ratio: 2/2.5;
}


.lineWeb {
    margin: 20px 0px 20px 0px;
}

@media only screen and (min-width: 320px) and (max-width:480px) {
    .lineMobile {
        margin: 20px 0px 20px 0px;
        width: 50%;
    }
}

/* 
.imgContainer:nth-child(18) .partnerImg {
    height: 176px;
} */

.partnerImg:hover {
    transform: scale(1.3);
    transition: all ease 0.4s;
}