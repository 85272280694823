

#root>div>div.bg-light.nav--bar>div.d-flex.justify-content-evenly.align-items-center.w-100.border-bottom>div.col-5.css-ikzlcq>div>div>div {
  padding: 0px 10px 0px 0px !important;
}

#root>div>div.bg-light.nav--bar>div.d-flex.justify-content-evenly.align-items-center.w-100.border-bottom>div.col-5.css-1p5q5e5-MuiStack-root>div>div>div {
  padding: 0px 10px 0px 0px !important;
}

@media only screen and (max-width: 600px) {
  input::placeholder {
    font: 1rem sans-serif;
  }

  .MuiInputAdornment-positionEnd {
    margin-left: 0px;
  }
}

input::placeholder {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
  font-family: "Tajawal", sans-serif !important;
}

input {
  font-family: "Tajawal", sans-serif !important;
  padding: 10.5px 4.5px 4.5px 4.5px;
}
.search-button a{
  color: white !important;

}
.mobile-search-button a{
  color: white !important;

}
.mobile-search-button{
  width: fit-content !important;
  display: flex !important;
  align-items: center !important;
}