html:lang(ar) {
  direction: rtl;
}

.text-primary {
  color: #294595 !important;
}

li {
  list-style: none;
}

.border-button {
  border: solid 1px #08223c !important;
  font-size: 3rem !important;
}

.navigation {
  position: relative;
  display: inline-block;
}

.navigation2 {
  position: relative;
  display: inline-block;
}

.navigation-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px !important;
  height: 300px;
  overflow-y: scroll;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation_content_last {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 15vw !important;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation_content_last2 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 15vw !important;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation:hover .navigation_content_last {
  display: block;
  position: absolute !important;
  top: 3rem !important;
}

.navigation2:hover .navigation_content_last2 {
  display: block;
  position: absolute !important;
  /* right: 225px !important;
  bottom: 45px !important; */
  transform: translate(-228px, -48px);
  height: 50vh;
  overflow-y: scroll;
}
.navigation2EN:hover .navigation_content_last2 {
  display: block;
  position: absolute !important;
  /* right: 225px !important;
  bottom: 45px !important; */
  transform: translate(228px, -48px);
  height: 50vh;
  overflow-y: scroll;
}

.navigation2EN:hover .navigation_content_last2 {
  display: block;
  position: absolute !important;
  /* right: 225px !important;
  bottom: 45px !important; */
  transform: translate(228px, -48px);
}

.navigation a,
.navigation span {
  color: black !important;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation2 a {
  color: black !important;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}

.navigation a:hover,
.navigation span:hover {
  background-color: #263997;
  color: white !important;
  border-radius: 4px;
}

.navigation2 a:hover {
  background-color: #263997;
  color: white !important;
  border-radius: 4px;
}

.keyboardArrow {
  rotate: -90deg;
  transition: ease all 0.2s;
}

.navigation:hover .keyboardArrow {
  rotate: 0deg;
  transition: ease all 0.2s;
}

.navigation2:hover .keyboardArrow {
  rotate: 0deg;
  transition: ease all 0.2s;
}

.navigation:hover .navigation-content {
  display: block;
}

.navigation2:hover .navigation-content {
  display: block;
}

.image--logo {
  width: 10%;
}

.logo_image {
  cursor: pointer !important;
}



.nav_bar {
  z-index: 200999;
  position: sticky !important;
  top: 0 !important;
}

.language-button {
  cursor: pointer;
}

.profile_btn {
  /* font-size: 30px !important; */
  color: black !important;
  border: #263997 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-btn-mobile {
  font-size: 14px !important;
  color: black !important;
}

.shoppingCart_mobile {
  color: #183696;

}

.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}


.shoppingCart {
  font-size: calc(1.275rem + 0.3vw) !important;
  color: #294595 !important;
}




.mobileNavbar {
  display: none !important;
}

.btn_signin {
  background-color: #184799 !important;
  border-radius: 6px !important;
  border: solid 1px #184799 !important;
  width: 40% !important;
  font-size: 1rem !important;
}

.btn_signup {
  width: 100% !important;
  font-size: 1rem !important;

}

.mobile_div_navbar {
  display: none;
}


.res-h1 {
  font-size: 20px !important;
}

.beta-version-header {
  background-color: #184799;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beta-version-animation {
  width: fit-content;
  margin: 0px;
  padding: 2px 0px 0px 0px;
}

.mobile_view_button_text {
  font-size: 11px !important;
}

.icon_color {
  color: #183696 !important;
}

.icon_arrow_width {
  font-size: 12px !important;
}

.dropdown-list-menuItem {
  height: 30vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  width: 100vw !important;
  font-size: 17px !important;
}

.active_nav {
  background-color: #183696 !important;
  padding: 10px;
  border-radius: 10px;
  color: #f1f1f1 !important;
}

.active_nav .icon-color,
.active_nav .icon_color_text {
  color: #f1f1f1 !important;
}

.active_icon_2 {
  display: none;
}

.active_nav .active_icon_2 {
  display: flex;
}

.active_nav .active_icon_1 {
  display: none;
}

.icon_nav_burger {
  font-size: 2rem !important;
}

.icon_color_text {
  color: #526581;
}


.font-size-h1 {
  font-size: 18px;
}

.fixed-arrow {
  position: fixed;
  width: 4px;
  height: 50px;
  background-color: #08223c;
  border-radius: 20px;
}

.btn-outline-primary:hover {
  background-color: #184799 !important;
  border: 1px solid #184799 !important;
  color: white !important;
}

.btn-outline-primary {
  border: 1px solid #184799 !important;
  color: #184799 !important;
}




@media only screen and (min-width: 1290px) {
  .btn--signin  {
   width: 100px !important;
  }
}
@media only screen and (min-width: 601px) {
  .logo_image {
    width: 75px !important;
    height: auto;
    margin: 2px 0px 2px 0px;
  }
}

@media only screen and (min-width: 600px) and (max-width:999px) {
  .navigation-content {
    max-width: 40vw !important;
    min-width: 40vw !important;
    min-height: 50vw !important;
    max-height: 50vw !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }
}

@media only screen and (max-width: 680px) {
  .shoppingCart {
    font-size: 0.8rem !important;
    margin-left: 0.2rem !important;
  }

  .btn--signin {
    font-size: 0.8rem !important;
    min-width: 23vw !important;
  }

  .account-circle {
    font-size: 0.6rem !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width:1400px) {
  .navigation-content {
    max-width: 40vw !important;
    min-width: 40vw !important;
    min-height: 35vh !important;
    max-height: 35vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

}

@media only screen and (max-width: 1000px) {
  .nav_bar {
    display: none !important;

  }

  .mobile_div_navbar {
    font-family: "Tajawal", sans-serif !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    display: none;
  }

  .btn_signin {
    font-size: 0.8rem !important;
    width: 50% !important;
  }
}
