.businessInfoCards {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 98%;
}

.filterContainer {
    background: #FFFFFF;
    height: 6.3rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;
    align-items: center;
    /* border */
    border-width: 0.96px 0.96px 0.96px 0;
    border-style: solid;
    border-color: #F6F6F6;
}

.groupFilter h6 {
    color: #201D23;
    font-weight: 400;
}

.filterBtn {
    border: 0.96px solid #F6F6F6;
    width: 16rem;
    height: 3rem;
    padding: 0.9rem;
    border-radius: 4px;
}

.searchContainer {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-block: 1.2rem;
}

.searchField {
    width: 30% !important;
    height: 2.4rem;
    padding: 0.60125rem 0.96125rem 0.60125rem 0.96125rem !important;
    margin-bottom: 2rem;
    font-size: 0.85rem !important;
    color: #201D2380 !important;
    background-color: #E3E3E34D !important;
}

.searchField:focus {
    border-color: unset !important;
    box-shadow: unset !important;
}

.clearTableBtn {
    border: 0.96px solid #F3F3F3;
    border-radius: 50%;
    padding: 0.6rem;
    height: fit-content;
    margin-right: 3rem;
    color: #183696;
    font-weight: 700;
}

.formSubmitBtn {
    border: 0.96px solid #F3F3F3;
    height: 3rem;
    padding: 0.9rem;
    padding-inline: 2.5rem;
    border-radius: 4px;
    margin-right: 3rem;
    font-weight: 600;
}

.tableHeaders {
    color: #183696 !important;
    text-align: center;
}

a {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
    /* text-decoration: underline; */
}