.businessInfoCards {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 98%;
}

.groupCardContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem, ;
}

.groupCard {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 7rem;
    margin: 1.5rem;
    padding: 1.35rem;
    box-shadow: 4px 4px 4rem 0px rgba(0, 0, 0, 0.1);
    border-radius: .6rem;
    cursor: pointer;
    transition: 0.2s;
}

.groupCard:hover {
    transform: translateY(-2%);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.1);
}

.groupCard>h3 {
    font-size: 1.2em;
    color: #183696;
    font-weight: 700;
}