.image-signUp {
    width: 33vw !important;
}

.signup--btn {
    font-size: 15px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@media only screen and (max-width: 765px) {

    .image-signUp {
        display: none !important;
    }



}

.signupFormWidth {
    width: 40%;
}