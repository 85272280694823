.rfpContainer {
    background-color: #273C97;
    position: relative;
    padding: 4rem;
    direction: ltr !important;
}

.topYellow {
    position: absolute;
    top: 0;
    left: 0;
    width: 60vw;
    height: auto;
    pointer-events: none;
}

.bottomYellow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60vw;
    height: auto;
    pointer-events: none;
}

.headerContainer,
.formContainer {
    max-width: 70rem;
    width: 100%;
    margin: auto;
}

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5rem;
    width: 10rem;
    height: 5rem;
    margin-bottom: 2rem;
}

.logoContainer img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.headerContainer {
    background: #FFFFFF;
    border-radius: 0.625rem;
    border: 2px solid #FFC107;
    padding: 1.5rem;
}

.headerContainer h2 {
    font-size: 1.125rem;
    font-weight: 700;
    color: #273C97;
    margin-bottom: 1rem;
}

.headerContainer h3 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #000000;
}

.headerContainer h4 {
    font-size: 1.5rem;
    font-weight: 400;
    color: #75787B;
    margin-bottom: 1rem;
}

.headerContainer span {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000000;
    margin-bottom: 1rem;
}

.headerContainer h5 {
    font-size: 0.9rem;
    font-weight: 400;
    color: #000000;
    margin-bottom: 1rem;
}

.headerContainer h5 a {
    color: #273C97 !important;
    text-decoration: underline !important;
    font-weight: 500 !important;
    transition: color 0.3s ease !important;
}

.headerContainer h5 a:hover {
    color: #1f317a !important;
    text-decoration: none !important;
}

.formContainer {
    background: #FFFFFF;
    border-radius: 0.625rem;
    border: 2px solid #FFC107;
    padding: 1.5rem;
    padding-right: 5rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.formContainer h3 {
    color: #000000;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 2rem;
}

.step {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.circle {
    border: 2px solid #FFC107;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size: 1.25rem;
    font-weight: 700;
    background-color: #fff;
    position: relative;
}

.step h1 {
    margin: 0 0 1.6rem 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #273C97;
}

.line {
    position: absolute;
    background-color: #FFC107;
    height: 1px;
    left: 4rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* Additional form styling */
.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.formGroup label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #000000;
    font-weight: 400;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    width: 50%;
    background: transparent;
    /* z-index: 999999999; */
}

.formGroupCheckbox {
    margin-bottom: 1rem;
    color: #000000;
    font-size: 1rem;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.2rem;
}

.submitBtn {
    padding: 0.5rem 1.5rem;
    background-color: #273C97;
    color: #FFFFFF;
    border: none;
    border-radius: 0.5rem;
    font-weight: 700;
    cursor: pointer;
    margin-top: 1rem;
    margin-left: 4.5rem;
}

.submitBtn:hover {
    background-color: #1f317a;
}

.formContent {
    margin-left: 4.5rem;
}

@media screen and (max-width: 60rem) {
    .rfpContainer {
        padding: 1.5rem;
    }

    .headerContainer h3 {
        font-size: 2rem;
    }

    .formContainer {
        padding: 1rem;
    }

    .formContent {
        margin-left: 2rem;
    }

    .formGroup {
        margin-bottom: 0.75rem;
    }

    .formGroup input,
    .formGroup select,
    .formGroup textarea {
        width: 90%;
    }

    .submitBtn {
        margin-left: 2rem;
    }
}