.businessInfoCards {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 98%;
}

.groupName {
    display: flex;
    width: fit-content;
    margin-block: 2rem;
    padding: 1.5rem 3rem;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    justify-content: space-between;
}

.groupName>h3 {
    font-size: 2rem;
    margin: auto;
    color: #183696;
    font-weight: 700;
}

.tableHeaders {
    color: #183696 !important;
    text-align: center;
}

.deleteUserBtn {
    border: 0;
    padding: 0;
    color: #EE201C;
    text-decoration: underline;
}